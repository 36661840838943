import consumer from "./consumer"

consumer.subscriptions.create("NotificationChannel", {
  received(data) {
    let navMenu= $("#nav-menu")
    let notificationBadge = navMenu.find('.nav__settings-notification')[0]
    const notificationBadgeNumber = notificationBadge.innerText
    let notificationContainer = navMenu.find('.nav__settings-notification-container')
    let notificationList = navMenu.find('.nav__settings-notification-list')
    let notifications = notificationList.find('a')

    if (!notificationBadgeNumber) {
      notificationBadge.innerText = 1
    } else if (parseInt(notificationBadgeNumber) < 3) {
      notificationBadge.innerText = parseInt(notificationBadgeNumber) + 1
    }
    switch (notifications.length) {
      case 0:
        notificationContainer.append('<h3 class="bold">Notifications</h3>')
        notificationContainer.append(`<div class="nav__settings-notification-list">${data}</div>`)
        break;

      case 3:
        notifications.last().remove()
        notificationList.prepend(data)
        break;

      default:
        notificationList.prepend(data)
        break;
    }
  }
});
